





























































































































import { getBasicCoursesForCourseContent, getMyCoursesForCourseContent } from "@/api/courseService";
import CourseContent from "@/components/courses/CourseContent.vue";
import handleError from "@/helpers/errors";
import { userModule } from "@/store/modules/user";
import Learnlink from "@learnlink/interfaces";
import { defineComponent, onMounted, reactive } from "@vue/composition-api";

export default defineComponent({
  components: { CourseContent },
  setup() {
    const vm = reactive({
      basicCourses: [] as Learnlink.Course.CourseContentParams[],
      courseIDsAndSessionsLeft: [] as Learnlink.Course.CourseContentParams[],
      loading: false,
    });

    onMounted(async () => {
      vm.loading = true;
      try {
        vm.courseIDsAndSessionsLeft = await getMyCoursesForCourseContent(userModule.state.userId);
        vm.basicCourses = await getBasicCoursesForCourseContent();
      }
      catch (e) {
        handleError(e);
      }
      vm.loading = false;
    });

    return {
      vm,
    };
  },
});
